/* eslint-disable @typescript-eslint/no-for-in-array */
/* eslint-disable @typescript-eslint/prefer-ts-expect-error */
/* eslint-disable @typescript-eslint/ban-ts-comment */

import { useEffect, useRef } from 'react';
import { fabric } from 'fabric';

interface IData {
  option_number: number;
  count: number;
  percentage: number;
  body: string;
}

interface IRating {
  averageRating: number | string;
  contribution: IData[];
}

interface ICurrentROSFeature {
  id: string;
  setting: {
    FontStyle: string;
    OptionsList: string;
    DisplayStyle: 'noOfVotes' | 'percentage' | string;
  };
  settings: {
    FontStyle: string;
    OptionsList: string;
    DisplayStyle: 'noOfVotes' | 'percentage' | string;
  };
  color: {
    ratingPollsBgColor: string;
    ratingPollsChartColor: string;
    ratingPollsLabelColor: string;
  };
}

const useRatingPollsCanvas = (
  ratingData: IRating,
  currentRosFeature: ICurrentROSFeature,
  streamId: string,
  canvasId: string,
  isReport = false

) => {
  let canvasRef = useRef<fabric.Canvas>();
  let currentRatingData = useRef<IRating | null>(null);
  let currentRatingRosFeature = useRef<ICurrentROSFeature | null>(null);

  const hasBeenResized = useRef(false);
  const backgroundColor = useRef<string | null>(null);

  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    backgroundColor.current = 'rgba(0,0,0,0)';
  }, [currentRosFeature.id]);

  useEffect(() => {
    if (!containerRef.current) return;

    currentRatingData.current = ratingData;
    currentRatingRosFeature.current = currentRosFeature;
    const height = containerRef.current.offsetHeight;
    const width = containerRef.current.offsetWidth;
    canvasRef.current = new fabric.Canvas(canvasId, {
      width,
      height,
      backgroundColor: backgroundColor.current || '#000000',
      containerClass: 'ratingPollWrapper',
    });
    ['object:moving', 'object:scaling', 'object:rotating', 'mouse:down', 'mouse:up',
      'mouse:move', 'mouse:wheel', 'mouse:dblclick', 'selection:cleared',
      'selection:created', 'mouse:singleclick'].forEach(event => canvasRef.current?.off(event));
  }, [currentRatingRosFeature.current?.id]);

  const draw = () => {
    const BAR_COLOR =
      currentRatingRosFeature.current?.color?.ratingPollsChartColor || '#F97B5F';
    const LABEL_COLOR =
      currentRatingRosFeature.current?.color?.ratingPollsLabelColor ||
      '#FFFFFF';

    const FONT_SIZE = 20;
    const FONT_FAMILY = 'Inter, sans-serif';
    const ANIMATION_DURATION = 1000;
    const canvas = canvasRef.current;
    if (!canvas || !ratingData || !canvasRef.current) return;
    canvas.enableRetinaScaling = true;

    if (currentRatingRosFeature.current?.color?.ratingPollsBgColor) {
      canvasRef.current?.setBackgroundColor(
        backgroundColor.current || '#000000',
        canvasRef.current.renderAll.bind(canvasRef.current)
      );
    }

    if (hasBeenResized.current) {
      hasBeenResized.current = false;
    }
    const canvasWidth = canvas.width || 800;
    const canvasHeight = canvas.height || 400;
    const barWidth =
      canvasWidth /
        ((currentRatingData.current?.contribution?.length || 5) * 2.5) || 100;

    const scoreFontAndPosition = canvasWidth * 0.013;

    const scoreYPosition = canvasHeight / (2 * 8);

    const maxBarHeight = canvasHeight - scoreYPosition - 100;

    const highestPercentage = currentRatingData?.current?.contribution ? Math.max(...currentRatingData.current?.contribution?.map((item: any) => item.percentage)) : 0;

    const sortedRatingPolls = currentRatingData?.current?.contribution?.sort((optionOne: IData, optionTwo: IData) => optionOne?.option_number - optionTwo?.option_number);
    if (!sortedRatingPolls) return;
    sortedRatingPolls.forEach((item: any, index: number) => {
      const value =
        currentRatingRosFeature?.current?.setting?.DisplayStyle ===
          'percentage' ||
        currentRatingRosFeature?.current?.settings?.DisplayStyle ===
          'percentage'
          ? `${Math.round(item.percentage).toString()}%`
          : item.count.toString();
      const left = index * (barWidth * 2.3) + barWidth;
      let barHeight = Math.min(
        (item.percentage / 50) * maxBarHeight,
        maxBarHeight
      );

      if (item.percentage === highestPercentage && highestPercentage !== 0) {
        barHeight = maxBarHeight;
      }
      const valueLabelHeight =
        barHeight === 0
          ? canvasHeight - barHeight - 100
          : (canvasHeight - barHeight) - 30;
      const gradient = new fabric.Gradient({
        type: 'linear',
        coords: { x1: 0, y1: 0, x2: 0, y2: barHeight },
        colorStops: [
          { offset: 0, color: BAR_COLOR },
          { offset: 1, color: BAR_COLOR },
        ],
      });
      const barObject = canvas.getObjects().find(
        // @ts-ignore
        (obj: { name: string }) => obj?.name === `bar-${item.option_number}`
      );
      const valueLabelObjectList = canvas.getObjects().filter(
        // @ts-ignore
        (obj: { name: string }) =>
          obj.name === `valueLabel-${item.option_number}`
      );

      const valueLabelObject =
        valueLabelObjectList[valueLabelObjectList.length - 1];

      const groupScore = canvas
        .getObjects()
        // @ts-ignore
        .filter((obj: { name: string }) => obj.name === 'groupScore');

      const latestGroupScore = groupScore[groupScore.length - 1];

      if (latestGroupScore) {
        // @ts-ignore
        latestGroupScore?.item(0).set('fill', BAR_COLOR);

        latestGroupScore
          // @ts-ignore
          .item(1)
          .set(
            'text',
            `Score ⭐️ ${currentRatingData?.current?.averageRating || 0.0}`
          );
        // @ts-ignore
        latestGroupScore.item(1).set('fill', LABEL_COLOR);
      } else {
        const left = (canvasWidth / 2) * 0.83;
        const scoreText = new fabric.Text(
          `Score ⭐️ ${currentRatingData?.current?.averageRating || 0.0}`,
          {
            left,
            top: canvasHeight - maxBarHeight,
            fontSize: scoreFontAndPosition,
            originX: 'center',
            textAlign: 'center',
            selectable: false,
            fill: LABEL_COLOR,
            evented: false,
            fontFamily: FONT_FAMILY,
          }
        );

        const paddedBackground = new fabric.Rect({
          left,
          top: canvasHeight - maxBarHeight - 10,
          fill: BAR_COLOR,
          // @ts-ignore
          width: scoreText?.getScaledWidth() + 50 || 200,
          // @ts-ignore
          height: scoreText?.getScaledHeight() + 20 || 150,
          originX: 'center',
          selectable: false,
          evented: false,
          rx: 24,
          ry: 24,
        });

        const groupScore = new fabric.Group([paddedBackground, scoreText], {
          name: 'groupScore',
          left,
          top: canvasHeight / (2 * 8),
          selectable: false,
          evented: false,
          originX: 'left'
        });

        canvas.add(groupScore);
      }

      if (!barObject) {
        const bar = new fabric.Rect({
          name: `bar-${item.option_number}`,
          objectCaching: true,
          width: barWidth,
          left,
          top: canvasHeight,
          height: 0,
          originY: 'bottom',
          selectable: false,
          evented: false,
          rx: 12,
          ry: 12,
          fill: gradient,
        });
        canvas.add(bar);
        if (isReport) {
          bar.set('height', barHeight);
        } else {
          bar.animate('height', barHeight, {
            duration: ANIMATION_DURATION,
            onChange: canvas.renderAll.bind(canvas),
          });
        }
      } else {
        barObject.set('fill', BAR_COLOR);

        if (isReport) {
          barObject.set('height', barHeight);
        } else {
          barObject.animate('height', barHeight, {
            duration: ANIMATION_DURATION,
            onChange: canvas.renderAll.bind(canvas),
          });
        }
      }
      if (!valueLabelObject) {
        const valueLabel = new fabric.Text(value, {
          name: `valueLabel-${item.option_number}`,
          left: left + barWidth / 2,
          top: canvasHeight - 80,
          fontSize: FONT_SIZE,
          fontFamily: FONT_FAMILY,
          originX: 'center',
          textAlign: 'center',
          selectable: false,
          fill: LABEL_COLOR,
          evented: false,
        });

        canvas.add(valueLabel);

        if (isReport) {
          valueLabel.set('top', valueLabelHeight);
        } else {
          valueLabel.animate('top', valueLabelHeight, {
            duration: ANIMATION_DURATION,
            onChange: canvas.renderAll.bind(canvas),
          });
        }
      } else {
        valueLabelObject.set('fill', LABEL_COLOR);
        valueLabelObject.set(
          // @ts-ignore
          'text',
          value
        );
        if (isReport) {
          valueLabelObject.set('top', valueLabelHeight);
        } else {
          valueLabelObject.animate('top', valueLabelHeight, {
            duration: ANIMATION_DURATION,
            onChange: canvas.renderAll.bind(canvas),
          });
        }
      }

      const labelGroupItem = canvas
        .getObjects()
        .find(
          (obj: any) => obj.name === `label-group${item.option_number.toString()}`
        );

      if (labelGroupItem) {
        // @ts-ignore
        labelGroupItem.item(0).set('fill', 'rgba(0,0,0,0.5)');
        // @ts-ignore
        labelGroupItem.item(1).set('fill', 'white');
      } else {
        const label = new fabric.Text(item.option_number.toString(), {
          left: left + barWidth / 2,
          top: canvasHeight - 20,
          fontSize: FONT_SIZE,
          originX: 'center',
          textAlign: 'center',
          selectable: false,
          fontFamily: FONT_FAMILY,
          fill: 'white',
          evented: false,
        });

        const labelBackground = new fabric.Rect({
          left: left + barWidth / 2,
          top: canvasHeight - 30,
          fill: 'rgba(0,0,0,0.5)',
          width: label.getScaledWidth() + 40,
          height: label.getScaledHeight() + 20,
          originX: 'center',
          selectable: false,
          evented: false,
          rx: 12,
          ry: 12,
        });
        const labelGroup = new fabric.Group([labelBackground, label], {
          name: `label-group${item.option_number.toString()}`,
          top: canvasHeight - 60,
          selectable: false,
          evented: false,
        });

        canvas.add(labelGroup);
      }
    });
  };
  useEffect(() => {
    currentRatingData.current = ratingData;
    currentRatingRosFeature.current = currentRosFeature;
    draw();
  }, [ratingData, currentRosFeature.id]);

  function resize() {
    if (
      !containerRef.current ||
      !canvasRef.current ||
      !canvasRef.current?.width
    ) {
      return;
    }

    canvasRef.current.clear();

    let objects = canvasRef.current.getObjects();

    const newWidth = containerRef.current.offsetWidth;

    const newHeight = containerRef.current.offsetHeight;

    const canvasWidth = canvasRef.current?.width ? canvasRef.current?.width : 0;
    const canvasHeight = canvasRef.current?.height
      ? canvasRef.current?.height
      : 0;

    const scaleX = newWidth / canvasWidth;
    const scaleY = newHeight / canvasHeight;
    canvasRef.current.setBackgroundColor(
      backgroundColor.current || '#000000',
      canvasRef.current.renderAll.bind(canvasRef.current)
    );

    for (let i in objects) {
      if (!objects[i]) return;
      objects[i].scaleX = (objects[i]?.scaleX || 0) * scaleX;
      objects[i].scaleY = (objects[i].scaleY || 0) * scaleY;
      objects[i].left = (objects[i].left || 0) * scaleX;
      objects[i].top = (objects[i].top || 0) * scaleY;
      objects[i].setCoords();
    }
    canvasRef.current.setWidth(canvasRef.current.getWidth() * scaleX);
    canvasRef.current.setHeight(canvasRef.current.getHeight() * scaleY);
    canvasRef.current.calcOffset();

    hasBeenResized.current = true;

    draw();
  }

  useEffect(() => {
    window.addEventListener('resize', resize, false);
    window.addEventListener('load', resize, false);
    window.addEventListener('orientationchange', resize, false);

    return () => {
      window.removeEventListener('resize', resize);
      window.removeEventListener('load', resize);
      window.removeEventListener('orientationchange', resize);
    };
  }, []);

  return { canvasRef, containerRef };
};

export default useRatingPollsCanvas;

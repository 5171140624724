/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
import React, {
  useEffect, useReducer, useRef, useState, forwardRef, useImperativeHandle
} from 'react';
import router, { useRouter } from 'next/router';
import { getTextTrackComments, updateEngageType } from '@services/youtube-platform.service';
import { isEqual } from 'lodash';
import WordChart from './WordChart';
import useNotificationTimer from '@lib/hooks/useNotificationTimer ';
import { chromaPaletteParser } from './helper/chromaPaletteParser';
import { colorPalette } from '@lib/constants';
import useFonts from '@lib/hooks/useFonts';

const TextTrack = forwardRef(({
  streamId, currentRosFeature, setting, color, isFullScreen, splitDiv, isResult = false,
  platformType,
  setShowNotification, analyticsData, livestreamData
}, ref) => {
  const [textTrackData, setTextTrackData] = useState([]);
  const [textTrackSettings, setTextTrackSettings] = useState(currentRosFeature?.setting || setting);
  const [textTrackColors, setTextTrackColors] = useState(currentRosFeature?.color || color);
  const previousDataRef = useRef([]);
  const highchartsRef = useRef(null);

  const newPalette = chromaPaletteParser().parse(colorPalette, textTrackColors.textTrackBgColor);

  useNotificationTimer(currentRosFeature, textTrackData, setShowNotification);

  const { getFontStyle } = useFonts();
  const { fontFamily } = getFontStyle(textTrackSettings.FontStyle || 'Sans-Serif');

  const bgColor = isResult ? (livestreamData?.colors?.textTrackBgColor || '#ffffff') : 'rgba(255, 255, 255, 0.0)';

  const initialChartOptions = {
    exporting: { enabled: false },
    chart: {
      backgroundColor: bgColor
    },
    plotOptions: {
      series: {},
      chart: { height: '100%' },
      wordcloud: {
        rotation: {
          from: 0,
          orientations: textTrackSettings?.Vertical ? 2 : 1,
          to: textTrackSettings?.Vertical ? 90 : 0
        },
        color: textTrackColors?.textTrackWordColor || '#319795',
        colorByPoint: textTrackColors?.textTrackMultipleColors,
        colors: textTrackColors?.textTrackMultipleColors ? newPalette : [textTrackColors?.textTrackWordColor || '#000000'],
        style: {
          fontFamily
        }
      }
    },
    credits: {
      enabled: false
    },
    responsive: {
      rules: [{
        condition: {
          maxWidth: 500
        },
        chartOptions: {
          legend: {
            enabled: false
          }
        }
      }]
    },
    series: [{
      type: 'wordcloud',
      data: textTrackData,
      name: 'Count'
    }],
    title: { text: '' }
  };

  const [chartOptions, dispatch] = useReducer((state, action) => {
    return {
      ...state,
      exporting: { enabled: false },
      chart: {
        backgroundColor: bgColor
      },
      plotOptions: {
        series: {},
        chart: { height: '100%' },
        wordcloud: {
          rotation: {
            from: 0,
            orientations: textTrackSettings?.Vertical ? 2 : 1,
            to: textTrackSettings?.Vertical ? 90 : 0
          },
          color: textTrackColors?.textTrackWordColor || '#319795',
          colorByPoint: textTrackColors?.textTrackMultipleColors,
          colors: textTrackColors?.textTrackMultipleColors ? newPalette : [textTrackColors?.textTrackWordColor || '#000000'],
          style: {
            fontFamily
          }
        }
      },
      credits: {
        enabled: false
      },
      responsive: {
        rules: [{
          condition: {
            maxWidth: 1400
          },
          chartOptions: {
            legend: {
              enabled: false
            }
          }
        }]
      },
      series: [{
        type: 'wordcloud',
        data: action.data,
        name: 'Count'
      }],
      title: { text: '' }
    };
  }, initialChartOptions);

  const joinType = useRouter().query?.joinType;

  const handleTextTrackComments = async () => {
    const response = await getTextTrackComments(streamId, textTrackSettings.ProfanityFilter);
    const hasDataChanged = !isEqual(response.entity, previousDataRef.current);
    if (hasDataChanged) {
      previousDataRef.current = response.entity;
      setTextTrackData(response.entity);
    }
  };

  useEffect(() => {
    if (isResult && analyticsData?.length > 0) return;

    updateEngageType(streamId, 'textTrack', { platformType });
    if (isResult) {
      setTextTrackSettings(setting);
      setTextTrackColors(color);
    } else if (currentRosFeature) {
      setTextTrackColors(currentRosFeature.color);
      setTextTrackSettings(currentRosFeature.setting);
    }
  }, [isResult, currentRosFeature]);

  useEffect(() => {
    if (isResult && analyticsData?.length > 0) return;
    if (!isResult) {
      handleTextTrackComments();
      const interval = setInterval(() => {
        handleTextTrackComments();
      }, 2000);
      return () => clearInterval(interval);
    }
    handleTextTrackComments();
    return undefined;
  }, [textTrackSettings]);

  useEffect(() => {
    dispatch({ data: textTrackData });
  }, [textTrackData]);

  const stopCurrentFeature = () => {
    router.push({ pathname: `/streams/${streamId}`, query: joinType && { joinType } });
  };

  useEffect(() => {
    if (isResult && analyticsData?.length > 0) setTextTrackData(analyticsData);
  }, [isResult, analyticsData]);

  useImperativeHandle(ref, () => ({ stopCurrentFeature }));

  useEffect(() => {
    if (isResult && analyticsData?.length > 0) return;
    if (textTrackData.length > 0 && setShowNotification) {
      setTimeout(() => {
        setShowNotification(false);
      }, 500);
    }
  }, [currentRosFeature, textTrackData]);

  return (
    isResult && <WordChart
      backgroundColor={bgColor}
      highchartsRef={highchartsRef}
      chartOptions={chartOptions}
      isFullScreen={isFullScreen}
      splitDiv={splitDiv}
      containerProps={isResult ? { style: { height: '100%', borderRadius: '8px' } } : { style: { width: splitDiv ? 'calc(100vw - 380px)' : '100%', height: '100%' } }}
      livestreamData={livestreamData}
      bgImg={livestreamData?.settings?.textTrackImageSrc}
      bgImgOpacity={(livestreamData?.settings?.textTrackImageOpacity ?? 0) / 100}
    />

  );
});

export default TextTrack;
